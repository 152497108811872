.value-name-label {
    font-size: 12pt;
}

.value-range-label {
    font-size: 10pt;
}

.warning-icon {
    color: #BD2727;
    width: 24px;
}