.card-dimensions-SetPassword {
    width: 36rem;
}


.backgroundImage-SetPassword {
    background-image: url('../../../public/Login.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw; 
    z-index: -200;
  }
  