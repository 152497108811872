.footer{
    background: #262A1E;
    bottom: 0;
    height: 100%;
}

.gradient-blur {
    z-index: 5;
    inset: auto 0 0 0;
    height: 110%;
    pointer-events: none;
  }
  .gradient-blur > div,
  .gradient-blur::before,
  .gradient-blur::after {
    position: absolute;
    inset: 0;
  }
  .gradient-blur::before {
    content: "";
    z-index: 1;
    backdrop-filter: blur(0.5px);
    mask: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 37.5%
      rgba(0, 0, 0, 1) 25%,
      rgba(0, 0, 0, 1) 12.5%,
      rgba(0, 0, 0, 0) 0%,
    );
  }
  .gradient-blur > div:nth-of-type(1) {
    z-index: 2;
    backdrop-filter: blur(1px);
    mask: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 50%
      rgba(0, 0, 0, 1) 37.5%,
      rgba(0, 0, 0, 1) 25%,
      rgba(0, 0, 0, 0) 12.5%,
    );
  }
  .gradient-blur > div:nth-of-type(2) {
    z-index: 3;
    backdrop-filter: blur(2px);
    mask: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 62.5%
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 1) 37.5%,
      rgba(0, 0, 0, 0) 25%,
    );
  }
  .gradient-blur > div:nth-of-type(3) {
    z-index: 4;
    backdrop-filter: blur(4px);
    mask: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 75%
      rgba(0, 0, 0, 1) 62.5%,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) 37.5%,
    );
  }
  .gradient-blur > div:nth-of-type(4) {
    z-index: 5;
    backdrop-filter: blur(8px);
    mask: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 87.5%
      rgba(0, 0, 0, 1) 75%,
      rgba(0, 0, 0, 1) 62.5%,
      rgba(0, 0, 0, 0) 50%,
    );
  }
  .gradient-blur > div:nth-of-type(5) {
    z-index: 6;
    backdrop-filter: blur(16px);
    mask: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 100%
      rgba(0, 0, 0, 1) 87.5%,
      rgba(0, 0, 0, 1) 75%,
      rgba(0, 0, 0, 0) 62.5%,
    );
  }
  .gradient-blur > div:nth-of-type(6) {
    z-index: 7;
    backdrop-filter: blur(32px);
    mask: linear-gradient(
      to top,
      rgba(0, 0, 0, 1) 100%
      rgba(0, 0, 0, 1) 87.5%,
      rgba(0, 0, 0, 0) 75%,
    );
  }
  .gradient-blur::after {
    content: "";
    z-index: 8;
    backdrop-filter: blur(64px);
    mask: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 87.5%,
      rgba(0, 0, 0, 1) 100%
    );
  }
  
.inline-block {
  position: relative;
  display: inline-block;
}

.inline-block span {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background: white;
}