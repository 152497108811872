.icon-bg {
    background-color: #ffffff;
    color: #000000;
}
.icon-bg:hover {
    background-color: rgb(243, 243, 243);
   
    
}

.user-container {
    z-index: 1;
}