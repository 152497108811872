.border-padding {
    padding: 0.13rem;
}

.border-back {
    border-radius: 1.8rem;
}

.border-card {
    border-radius: 1.7rem;
}

/* Colore Bordo */

.border-default {
    background: #dddddd;
}
.hot {
    background: linear-gradient(to bottom, #dddddd 50%, #FFD29D 70%);
}

.cold {
    background: linear-gradient(to bottom, #dddddd 50%, #75e6ffbf 70%);
}

.heavy-rain {
    background: linear-gradient(to bottom, #87B7FF 50%, #dddddd 70%);
}

.light-rain {
    background: linear-gradient(to bottom, #dddddd 50%, #87B7FF 70%);
}

.low-humidity {
    background: linear-gradient(to bottom, #dddddd 10%, rgba(153, 29, 133, 0.5) 50%, #000d84b7 100%);
}

.high-humidity {
    background: linear-gradient(to bottom, #dddddd 10%, rgba(153, 29, 133, 0.5) 50%, #000D84 100%);
}

.light-wind {
    background: linear-gradient(to bottom, #dddddd 50%, #c9ffef 70%);
}

.strong-wind {
    background: linear-gradient(to bottom, #dddddd 50%, #a7ffe5 70%);
}

/* Colore Icone */

.i-default {
    filter: invert(100%) sepia(2%) saturate(2924%) hue-rotate(211deg) brightness(112%) contrast(73%);
}
.i-hot {
    filter: invert(99%) sepia(82%) saturate(2704%) hue-rotate(299deg) brightness(112%) contrast(105%);
}
.i-cold {
    filter: invert(73%) sepia(69%) saturate(496%) hue-rotate(165deg) brightness(103%) contrast(106%);
}
.i-heavy-rain {
    filter: invert(78%) sepia(11%) saturate(6561%) hue-rotate(185deg) brightness(100%) contrast(101%);
}
.i-light-rain {
    filter: invert(75%) sepia(26%) saturate(4340%) hue-rotate(188deg) brightness(104%) contrast(101%);
}
.i-low-humidity {
    filter: invert(91%) sepia(14%) saturate(816%) hue-rotate(189deg) brightness(89%) contrast(103%);
}
.i-high-humidity {
    filter: invert(72%) sepia(9%) saturate(761%) hue-rotate(196deg) brightness(89%) contrast(93%);
}
.i-light-wind {
    filter: invert(91%) sepia(2%) saturate(6433%) hue-rotate(93deg) brightness(105%) contrast(137%);
}
.i-strong-wind {
    filter: invert(94%) sepia(10%) saturate(663%) hue-rotate(112deg) brightness(94%) contrast(87%);
}