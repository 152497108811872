.image-size {
    width: 30rem
}

.trend-text {
    font-size: 9.5pt;
}

.increasing-info-text {
    font-size: 10pt;
}