.card-dimensions-register {
  width: 48rem;
}

.backgroundImage-register {
    background-image: url('../../../public/images/requestHarvest.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 110vh; /* Assicurati che copra l'intero schermo in altezza */
    width: 100vw; /* Assicurati che copra l'intera larghezza dello schermo */
    z-index: -200;
  }

  .backgroundImage-insurance {
    background-image: url('../../../public/images/insurance-top.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 110vh; /* Assicurati che copra l'intero schermo in altezza */
  }
  
  .title-register {
    font-family: 'Montserrat-Variable';
  }

  @media (max-width: 640px) {
    .card-dimensions-register {
      width: 100%;
      background: rgba(255, 255, 255, 0.8); /* Aggiungi uno sfondo semitrasparente */
      overflow: hidden;
      height: 100%;
    }
  }

@media (max-width: 394px) {
  .backgroundImage-register {
    height: 130vh; /* Assicurati che copra l'intero schermo in altezza */
  }
}