/* Calendar container styling */
.calendar-container {
  display: flex;
  height: 100vh;
  width: 100%;
  padding: 1.25rem;
  background-color: #fff;
}

.calendar-wrapper {
  flex: 1;
  margin-right: 1rem;
}
.fc-popover-body{
  background-color: white
}

.fc-day-today { /* oggi non selezionato in arancio */
  background-color: white !important;
}

.fc-day-today .fc-daygrid-day-number {
  font-weight: bold;
  text-decoration: underline 2px #FFAF54;
  color: black;
}

/* Column header styling */
.fc-col-header-cell {
  background-color: #ebfbff;
  text-align: center;
  font-weight: bold;
  font-size: 1rem; /* 16px */
}

.fc-daygrid-day-number {
  text-align: left !important;
}

/* Event styling */
.fc-daygrid-event {
  background-color: #3788d8 !important; /* Event background color */
  color: white !important; /* Event text color */
  border: 1px solid #3788d8 !important; /* Event border color */
  border-radius: 3px !important; /* Event border radius */
  padding: 2px 4px !important; /* Event padding */
  margin: 2px 0 !important; /* Event margin */
  overflow: hidden !important; /* Hide overflow */
}

/* Event hover styling */
.fc-daygrid-event:hover {
  background-color: #285a96 !important; /* Change background color on hover */
  border-color: #285a96 !important; /* Change border color on hover */
}

/* Responsive styling */
@media (max-width: 48rem) { /* 768px */
  .calendar-container {
    flex-direction: column;
    padding: 0.625rem; /* 10px */
  }
  .fc-toolbar-title {
    font-size: 1.5rem; /* 24px */
  }
  .sidebar-note {
    width: 100%;
    margin-top: 1rem;
  }
}

/* Sidebar styling */
.sidebar-note {
  background: white;
  border-left: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1000;
  overflow: hidden;
}

.sidebar-note .close-button {
  top: 20px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.sidebar-note .event-details {
  padding: 20px;
}


/* SIDEBARNOTE */


.scrollable-description {
  max-height: 37rem; /* Imposta l'altezza massima desiderata */
  overflow-y: auto; /* Abilita lo scroll verticale */
}

/* Nascondi la scrollbar su Webkit (Chrome, Safari) */
.scrollable-description::-webkit-scrollbar {
  width: 10px;
   /* Facoltativo: può essere usato per colori */
}

/* Nascondi la scrollbar su Firefox */
.scrollable-description {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}
