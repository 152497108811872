.glass{
    background: rgba(255, 255, 255, 0.407);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.card-dimensions {
    width: 36rem;
}


.backgroundImage-customer {
    background-image: url('../../../public/Login.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw; 
    z-index: -200;
  }
  
@media (max-width: 362px) {
    .small-title {
        font-size: 2.5rem;
    }
}