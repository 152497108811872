.transfer-button {
    background-color: #4CAF50;
    color: white;
}

.notification-modal {
    height: 40vh;
    width: 45vw;
}

.notification-list {
    overflow-y: auto;
    height: 100%;
}