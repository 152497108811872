html, body, #root {
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #F7F7F7;
}

main {
  flex: 1;
  overflow: hidden;
}
