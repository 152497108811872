.sidebar {
    width: 35rem;
    z-index: 1;
}

.sidebar-header-title {
    font-size: 20pt;
}
.sidebar-header-subtitle {
    font-size: 16pt;
}

.sidebar-content {
    overflow: hidden !important;
}

.scroll-bar {
    height: 100%;
    overflow-y: auto;
}

.tab-bar-button {
    font-size: 14pt;
}

.sidebar-header {
    z-index: 9999;
    position: relative;
}
/* Sidebar.css */
.save-button-container {
    height: 3rem; /* Altezza del bottone "Salva" */
    position: relative;
}

.save-button-container.hidden {
    visibility: hidden;
    opacity: 0;
    transform: translateY(50px);
}

.save-button-container.visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}
