
.big-title{
  font-family: 'Montserrat-Variable';
  font-size: 60pt;
}

.big-title-md {
  font-family: 'Montserrat-Variable';
  font-size: 60pt;
}

.header-subtitle {
  font-family: 'Montserrat-Variable';
  font-size: 50pt;
}

.header-subtitle-md {
  font-family: 'Montserrat-Variable';
  font-size: 50pt;
}

.header-description {
  font-family: 'Montserrat-Variable';
  font-size: 30pt;
}
.description {
  font-size: 15pt;
}

.header-description-insurance  {
  font-size: 2rem;
}

.caption {
  width: 100%;
  font-size: 14pt;
}

.caption-md {
  font-family: 'Montserrat-Variable';
  font-size: 16pt;
}

.details-price {
  font-size: 8pt;
}

.landing-bg {
  background-image: url('../../../public/images/background-landing.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1; /* Assicura che sia dietro tutto il contenuto */
  filter: blur(5px);
  --webkit-filter: blur(5px);
  height: 100%;
  transform: scale(1.02);
}

.mobile-img{
  background-image: url('../../../public/images/ImgLandingTop.png');
  background-size: cover;
  background-position: center;
  height: 100%; 
  width: 100%; 
  z-index: -1;
}

.devices-img {
  background-image: url('../../../public/images/devices-Website.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 45rem; 
  width: 45rem; 
}

.service-img{
  background-image: url('../../../public/images/services-bg.png');
  background-size: cover;
  background-position: center;
  height: 100%; 
  width: 100%; 
  z-index: -6;

}

.blockchain-img{
  background-image: url('../../../public/images/PageBlockchain.jpg');
  background-size: cover;
  height: 100%; 
  width: 100%; 
  z-index: -8;
}

.insurance-img-top{
  background-image: url('../../../public/images/insurance-top.png');
  background-size: cover;
  background-position: bottom;
  height: 100vh; 
  width: 100%; 
}

.insurance-img-bottom{
  background-image: url('../../../public/images/Insurance-bottom.png\ 00-43-51-031.png');
  background-size: cover;
  height: 100%; 
  width: 100%;
}

.insurance-devices-img {
  background-image: url('../../../public/images/insurance-devices.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 30rem; 
  aspect-ratio: 1/1;
}

.feature-description {
  font-size: 16pt;
}

.harvey-service {
  background-image: url('../../../public/images/Harvey-Service.jpeg');
  background-size: cover;
  background-position: center;
  height: 100%; 
  width: 100%; 
}

.service-description {
  font-size: 12pt;
}


@media (max-width: 640px) {
  .big-title{
    font-family: 'Montserrat-Variable';
    font-size: 40pt;
  }

  .description {
    font-size: 18pt;
  }

  .header-subtitle {
    font-size: 4rem;
  }

  .header-subtitle-md {
    font-size: 3.5rem;
  }

  .header-description {
    font-size: 2rem;
  }
  .header-description-insurance  {
    font-size: 1.5rem;
  }

  .caption {
    font-size: 10pt;
  }
  .devices-img {
    height: 20rem; 
    width: 20rem; 
  }

  .insurance-img-top {
    background-image: none;
    height: 50vh; 
  }

  .service-description {
    font-size: 16pt;
  }

  .big-title-md {
    font-size: 38pt;
  }

}

@media (max-width: 412px) {
  .big-title{
    font-family: 'Montserrat-Variable';
    font-size: 40pt;
  }

  .description {
    font-size: 17pt;
  }

  .header-subtitle {
    font-size: 4rem;
  }

  .header-subtitle-md {
    font-size: 3rem;
  }

  .header-description {
    font-size: 2rem;
  }
  .header-description-insurance  {
    font-size: 1.5rem;
  }

  .caption {
    font-size: 10pt;
  }
  .devices-img {
    height: 20rem; 
    width: 20rem; 
  }

  .insurance-img-top {
    background-image: none;
    height: 50vh; 
  }

  .service-description {
    font-size: 16pt;
  }

  .big-title-md {
    font-size: 32pt;
  }

}

@media (max-width: 362px) {
  .big-title{
    font-family: 'Montserrat-Variable';
    font-size: 40pt;
  }

  .description {
    font-size: 17pt;
  }

  .header-subtitle {
    font-size: 4rem;
  }

  .header-subtitle-md {
    font-size: 2.5rem;
  }

  .header-description {
    font-size: 2rem;
  }
  .header-description-insurance  {
    font-size: 1.5rem;
  }

  .caption {
    font-size: 10pt;
  }
  .devices-img {
    height: 20rem; 
    width: 20rem; 
  }

  .insurance-img-top {
    background-image: none;
    height: 50vh; 
  }

  .service-description {
    font-size: 16pt;
  }

  .big-title-md {
    font-size: 30pt;
  }

}